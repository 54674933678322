import { Box } from "@mui/material"
import { useCurrentUser } from "../../hooks/contexts/currentUserContext"
import TextParagraph from "../../assets/components/TextParagraph"
import ActivateAccount from "../ActivateAccount"
import { Instrument } from "../../utils/types"
import Text from "../../assets/components/Text"
import { usePostMqttResetNetwork } from "../../mqtt/mqttHandlerContext"
import BasicButton from "../../assets/components/BasicButton"
import FlashOnIcon from "@mui/icons-material/FlashOn"
import { useConnectedInstruments } from "../../hooks/contexts/connectedInstrumentsContext"

type PaymentPlan = keyof typeof PaymentPlans

const PaymentPlans = {
    monthly: "Månadsvis",
    quarterly: "Kvartalsvis",
    yearly: "Årsvis",
}

const sortOnType = (a: Instrument, b: Instrument) => {
    if (a.type < b.type) {
        return -1
    }
    if (a.type > b.type) {
        return 1
    }
    return 0
}

const License = () => {
    const currentUser = useCurrentUser()
    const connectedInstruments = useConnectedInstruments()
    const resetCurrentNetwork = usePostMqttResetNetwork()
    const alias = currentUser?.customer?.alias as string
    const paymentPlan = currentUser?.customer?.paymentPlan as PaymentPlan
    const instruments = currentUser?.customer?.instruments?.sort(sortOnType) as Instrument[]

    function isConnected(deviceId: string) {
        return connectedInstruments.some((instrument: Instrument) => instrument.deviceId === deviceId)
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "20px" }}>
            {currentUser?.customer ? (
                <>
                    <TextParagraph heading="Licensägare" body={alias} />

                    <TextParagraph heading="Betalplan" body={paymentPlan && PaymentPlans[paymentPlan]} />

                    {instruments ? (
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "10px" }}>
                            <TextParagraph heading="Instrument som kopplats" />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "flex-start",
                                    gap: "3rem",
                                    flexWrap: "wrap",
                                }}
                            >
                                {instruments.map(
                                    (instrument: Instrument) =>
                                        instrument.serialNr && (
                                            <Box key={instrument.deviceId} sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "0.5rem" }}>
                                                <Text>{instrument.serialNr}</Text>
                                                {isConnected(instrument.deviceId) && (
                                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                        <Box>
                                                            <FlashOnIcon sx={{ color: "green" }} />
                                                            Aktiv
                                                        </Box>
                                                        <BasicButton
                                                            title="Återställ WiFi-lösenord"
                                                            variant="contained"
                                                            onClick={() => {
                                                                resetCurrentNetwork(instrument.deviceId)
                                                            }}
                                                        />
                                                    </Box>
                                                )}
                                            </Box>
                                        )
                                )}
                            </Box>
                        </Box>
                    ) : (
                        <TextParagraph heading="Instrument som kopplats" body="Inga instrument har kopplats" />
                    )}
                </>
            ) : (
                <ActivateAccount />
            )}
        </Box>
    )
}

export default License
