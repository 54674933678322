import { createContext, useState, ReactNode, useEffect, useContext, Dispatch, SetStateAction, useCallback } from "react"
import { Instrument, InstrumentType, Song, SONG_TYPES } from "../../utils/types"
import { useBeforeUnload } from "react-router-dom"
// import { songList } from "../../utils/songList"
import { useMqttIsConnected, usePostMqttChangeSong } from "../../mqtt/mqttHandlerContext"
import { useCurrentUser } from "./currentUserContext"
import { userSongList } from "../../utils/songList"
import { useConnectedInstruments, useUpdateConnectedInstrument } from "./connectedInstrumentsContext"
import { buttonSounds, joystickSounds, touchSounds } from "../../utils/api"

export type ChangeCurrentSong = ({ songId, songType, instrumentSound, deviceId }: { songId?: string; songType?: SONG_TYPES; instrumentSound?: string; deviceId?: string }) => void

interface CurrentSongContextProps {
    currentSong: Song | undefined
    changeCurrentSong: ChangeCurrentSong
    currentlyPlayingIsExpanded: boolean
    setCurrentlyPlayingIsExpanded: Dispatch<SetStateAction<boolean>>
}

const CurrentSongContext = createContext<CurrentSongContextProps>({
    currentSong: undefined,
    changeCurrentSong: () => {},
    currentlyPlayingIsExpanded: true,
    setCurrentlyPlayingIsExpanded: () => {},
})

const saveToLocalStorage = (song: string) => {
    // Save the current song in local storage for
    // access in next session
    localStorage.setItem("currentSong", song as string)
}

const readLastCurrentSong = () => {
    // Read the current song from local storage to
    // resume last session
    return localStorage.getItem("currentSong")
}

export const CurrentSongProvider = ({ children }: { children: ReactNode }) => {
    const [currentSong, setCurrentSong] = useState<Song | undefined>(undefined)
    const [currentlyPlayingIsExpanded, setCurrentlyPlayingIsExpanded] = useState<boolean>(true)
    const mqttIsConnected = useMqttIsConnected()
    const postMqttChangeSong = usePostMqttChangeSong()
    const currentUser = useCurrentUser()
    const connectedInstruments = useConnectedInstruments()
    const updateConnectedInstruments = useUpdateConnectedInstrument()

    function getDefaultSound(type: InstrumentType) {
        if (type === "joysticks") {
            return joystickSounds[0].id
        }
        if (type === "buttons") {
            return buttonSounds[0].id
        }
        if (type === "touch") {
            return touchSounds[0].id
        }
    }

    const songList = currentUser?.songList

    const changeCurrentSong = ({ songId, songType, instrumentSound, deviceId }: { songId?: string; songType?: SONG_TYPES; instrumentSound?: string; deviceId?: string }) => {
        const songObject = songList?.find((songObj) => songObj.id === songId)

        if (!deviceId) {
            connectedInstruments.forEach((instrument: Instrument) => {
                updateConnectedInstruments({
                    deviceId: instrument.deviceId,
                    serialNr: instrument.serialNr,
                    type: instrument.type,
                    currentSong: {
                        ...instrument.currentSong,
                        category: songObject?.category.toLowerCase(),
                        key: songObject?.keytone,
                        songType: songObject?.type,
                        id: songObject?.id,
                        sound: songType === SONG_TYPES.KEY ? getDefaultSound(instrument.type) : undefined,
                    },
                })
            })
            setCurrentSong(songObject)
            if (songType === SONG_TYPES.KEY) {
                connectedInstruments.forEach((instrument: Instrument) => {
                    postMqttChangeSong({ message: songObject?.keytone + "-" + songObject?.category + "-" + getDefaultSound(instrument.type), deviceId: instrument.deviceId })
                })
            } else if (songObject) {
                connectedInstruments.forEach((instrument: Instrument) => {
                    postMqttChangeSong({ message: songObject?.id, deviceId: instrument.deviceId })
                })
            }
        }

        if (songType === SONG_TYPES.KEY && instrumentSound && deviceId) {
            const instrumentToUpdate = connectedInstruments.find((instrument: Instrument) => instrument.deviceId === deviceId)

            if (instrumentToUpdate) {
                updateConnectedInstruments({
                    ...instrumentToUpdate,
                    currentSong: {
                        ...instrumentToUpdate.currentSong,
                        sound: instrumentSound,
                    },
                })
                postMqttChangeSong({ message: instrumentToUpdate?.currentSong?.key + "-" + instrumentToUpdate?.currentSong?.category + "-" + instrumentSound, deviceId: deviceId })
            }
        }
    }

    useEffect(() => {
        // Read current song from last session
        const lastSongId = readLastCurrentSong()
        if (lastSongId) {
            // Get song details
            const songObject = songList?.find((songObj) => songObj.id === lastSongId)

            // Set current song
            setCurrentSong(songObject)
        }
    }, [])

    // useEffect(() => {
    //     // Message change to instruments
    //     if (currentSong && mqttIsConnected) {
    //         postMqttChangeSong({ message: currentSong.id })
    //     }
    // }, [currentSong, mqttIsConnected])

    // Save to local storage to resume next time
    useBeforeUnload(
        useCallback(() => {
            saveToLocalStorage(currentSong?.id as string)
        }, [currentSong])
    )

    return <CurrentSongContext.Provider value={{ currentSong, changeCurrentSong, currentlyPlayingIsExpanded, setCurrentlyPlayingIsExpanded }}>{children}</CurrentSongContext.Provider>
}

export const useCurrentSong = () => useContext(CurrentSongContext)?.currentSong
export const useChangeCurrentSong = () => useContext(CurrentSongContext)?.changeCurrentSong
export const useCurrentlyPlayingIsExpanded = () => useContext(CurrentSongContext)?.currentlyPlayingIsExpanded
export const useSetCurrentlyPlayingIsExpanded = () => useContext(CurrentSongContext)?.setCurrentlyPlayingIsExpanded
