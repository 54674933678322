import React, { useEffect, useRef, useState } from "react"
import buttons from "../../../images/Instrument-Buttons.png"
import levers from "../../../images/Instrument-Levers.png"
import joysticks from "../../../images/Instrument-Joysticks.png"
import touch from "../../../images/Instrument-Touch.png"
import BasicButton from "../../../assets/components/BasicButton"
import { VolumeUp, VolumeMute, VolumeDown, VolumeOff } from "@mui/icons-material"

import { Box, Container, Slider, ToggleButtonGroup, ToggleButton } from "@mui/material"

import { useIsSmallScreen } from "../../../hooks/useIsSmallScreen"
import { usePostMqttChangeSong, usePostMqttChangeVolume } from "../../../mqtt/mqttHandlerContext"
import { useCurrentSong } from "../../../hooks/contexts/currentSongContext"
import { InstrumentType, SONG_TYPES, Song, Instrument } from "../../../utils/types"
import { Drawer } from "../Welcome"
import Text from "../../../assets/components/Text"
import MasterVolume from "./MasterVolume"
import { formatString } from "../../../utils/formatter"
import InstrumentSettings from "./InstrumentSettings"
import { useConnectedInstruments } from "../../../hooks/contexts/connectedInstrumentsContext"

// Returnerar korrekt bild beroende på vilket instrument id vi anropar med
function handleImageSource(type: InstrumentType): string {
    switch (type) {
        case "buttons":
            return buttons
        case "touch":
            return touch
        case "joysticks":
            return joysticks
        default:
            return buttons
    }
}

const VolumeIcon = ({ value }: { value: number }) => {
    if (value === 0) {
        return <VolumeOff />
    }
    if (value <= 0.3 && value > 0) {
        return <VolumeMute />
    }
    if (value < 0.3 && value >= 0.7) {
        return <VolumeDown />
    }
    if (value > 0.7) {
        return <VolumeUp />
    } else {
        return <VolumeDown />
    }
}

interface InstrumentsProps {
    currentSong: Song | undefined
    instrumentVolumes: { [key: string]: number }
    setInstrumentVolumes: React.Dispatch<React.SetStateAction<{ [key: string]: number }>>
}

const Instruments = ({ currentSong, instrumentVolumes, setInstrumentVolumes }: InstrumentsProps) => {
    const postMqttChangeVolume = usePostMqttChangeVolume()
    // const currentSong = useCurrentSong()
    const isSmallScreen = useIsSmallScreen()
    const [isOpen, setIsOpen] = useState(false)
    const [selectedInstrument, setSelectedInstrument] = useState<Instrument>({ deviceId: "", type: "", serialNr: "" })

    const connectedInstruments = useConnectedInstruments()
    // console.log("connected Instruments ", connectedInstruments)
    const shouldShowSettings = currentSong?.type === SONG_TYPES.KEY

    const toggleDrawer = ({ instrumentId, instrumentType, instrumentSerialNr }: { instrumentId?: string; instrumentType?: InstrumentType; instrumentSerialNr?: string }) => {
        setIsOpen(!isOpen)
        setSelectedInstrument({
            deviceId: instrumentId ?? "",
            type: instrumentType ?? "",
            serialNr: instrumentSerialNr ?? "",
        })
    }

    const handleSlider = (event: React.ChangeEvent<HTMLInputElement>, instrument: any) => {
        const newVolume = parseFloat(event.target.value)
        setInstrumentVolumes((prevVolumes: any) => ({
            ...prevVolumes,
            [instrument]: newVolume,
        }))
        postMqttChangeVolume(newVolume.toString(), instrument)
    }

    // Fetch initial volume values from storage when the component mounts
    useEffect(() => {
        const storedVolumesString = localStorage.getItem("instrumentVolumes")
        if (storedVolumesString) {
            const storedVolumes = JSON.parse(storedVolumesString)
            setInstrumentVolumes(storedVolumes)
        }
    }, [])

    // Save volume values to storage whenever they change
    useEffect(() => {
        localStorage.setItem("instrumentVolumes", JSON.stringify(instrumentVolumes))
    }, [instrumentVolumes])

    return connectedInstruments && connectedInstruments.length > 0 ? (
        <Box sx={{ display: "grid", height: "auto", justifyContent: "center", alignItems: "center", gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 2fr 1fr" }}>
            <Box></Box>
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 1fr",
                    gap: isSmallScreen ? "1rem" : "3rem",
                }}
            >
                {connectedInstruments.map((instrument: Instrument) => (
                    <Box key={instrument.deviceId} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box height={"100%"} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                {/* <VolumeUp fontSize="small" sx={{ mb: 1 }} /> */}
                                <VolumeIcon value={instrumentVolumes[instrument.deviceId]} />
                                <Slider
                                    sx={{
                                        width: "5px",
                                        "& .MuiSlider-thumb": {
                                            color: "white",
                                            height: "5px",
                                            borderRadius: "2px",
                                        },
                                        "& .MuiSlider-track": {
                                            color: "#77e077",
                                        },
                                        "& .MuiSlider-rail": {
                                            color: "#acc4e4",
                                        },
                                        "& .MuiSlider-active": {
                                            color: "green",
                                        },
                                    }}
                                    aria-label="Slider"
                                    orientation="vertical"
                                    valueLabelDisplay="auto"
                                    value={instrumentVolumes[instrument.deviceId] ?? 0.5}
                                    min={0}
                                    max={1}
                                    step={0.1}
                                    onChange={(event: any) => handleSlider(event, instrument.deviceId)}
                                />
                            </Box>

                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <img src={handleImageSource(instrument.type)} alt="Bild på instrument" width={isSmallScreen ? "50%" : "80%"} />
                                {shouldShowSettings && isSmallScreen && (
                                    <>
                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <BasicButton
                                                fullWidth={isSmallScreen}
                                                variant="outlined"
                                                title="Välj ljud"
                                                onClick={() => toggleDrawer({ instrumentId: instrument.deviceId, instrumentType: instrument.type, instrumentSerialNr: instrument.serialNr })}
                                                sx={{ height: "50%" }}
                                            />
                                        </Box>
                                        <Drawer variant="bottom" isOpen={isOpen} toggleDrawer={() => toggleDrawer({})} heading={`${selectedInstrument.deviceId} inställningar`}>
                                            <InstrumentSettings currentSong={currentSong} selectedInstrument={selectedInstrument} />
                                        </Drawer>
                                    </>
                                )}
                                {/* <p>deviceId: {instrumentId}</p> */}
                            </Box>
                        </Box>

                        {shouldShowSettings && !isSmallScreen && (
                            <>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <BasicButton
                                        variant="outlined"
                                        title="Välj ljud"
                                        onClick={() => toggleDrawer({ instrumentId: instrument.deviceId, instrumentType: instrument.type, instrumentSerialNr: instrument.serialNr })}
                                    />
                                </Box>
                                <Drawer variant="bottom" isOpen={isOpen} toggleDrawer={() => toggleDrawer({})} heading="Välj ljud">
                                    <InstrumentSettings currentSong={currentSong} selectedInstrument={selectedInstrument} />
                                </Drawer>
                            </>
                        )}
                    </Box>
                ))}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "flex-end" }}>{!isSmallScreen && <MasterVolume instrumentVolumes={instrumentVolumes} setInstrumentVolumes={setInstrumentVolumes} />}</Box>
        </Box>
    ) : (
        <Box sx={{ display: "flex", justifyContent: "center", bottom: "50%", position: "absolute" }}>
            <h4>Väntar på Funki instrument...</h4>
        </Box>
    )
}

export default Instruments
