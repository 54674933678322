import React, { useEffect, useState } from "react"
import logo from "../assets/funki_VAG_1280_red.png"
import { styled } from "@mui/material/styles"
import { Card, CardContent, CardMedia, Stack, Box, Chip } from "@mui/material"
import Collapse from "@mui/material/Collapse"
import IconButton, { IconButtonProps } from "@mui/material/IconButton"
import { Song } from "../utils/types"
import Text from "../assets/components/Text"

import { VolumeUp, PlayCircleFilledWhite, ExpandMore as ExpandMoreIcon } from "@mui/icons-material"
import TextRow from "../assets/components/TextRow"
import { useIsSmallScreen } from "../hooks/useIsSmallScreen"
import { ChangeCurrentSong } from "../hooks/contexts/currentSongContext"

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props
    return <IconButton {...other} />
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}))

const SongCard = ({ song, currentSong, changeCurrentSong }: { song: Song; currentSong: Song | null | undefined; changeCurrentSong: ChangeCurrentSong }) => {
    const [isSelected, setIsSelected] = useState<boolean>(false)

    const isSmallScreen = useIsSmallScreen()

    const hasAdditionalInformation = song.artist || song.keytone || song.tempo || song.backingTrack?.src

    useEffect(() => {
        if (currentSong?.id === song.id) {
            setIsSelected(true)
        } else {
            setIsSelected(false)
        }
    }, [currentSong, song.id])

    return isSmallScreen ? (
        <MobileCard changeCurrentSong={changeCurrentSong} hasAdditionalInformation={hasAdditionalInformation} isSelected={isSelected} song={song} />
    ) : (
        <DesktopCard changeCurrentSong={changeCurrentSong} hasAdditionalInformation={hasAdditionalInformation} isSelected={isSelected} song={song} />
    )
}

interface DesktopCardProps {
    isSelected: boolean
    song: Song
    changeCurrentSong: ChangeCurrentSong
    hasAdditionalInformation: string | undefined
}

const MobileCard = ({ isSelected, song, changeCurrentSong, hasAdditionalInformation }: DesktopCardProps) => {
    return (
        <Card
            sx={{
                display: "flex",
                width: "100%",
                background: isSelected ? "white" : "white",
                cursor: "pointer",
                outline: isSelected ? "2px solid lightgreen" : "",
                position: "relative",
            }}
            onClick={() => {
                changeCurrentSong({ songId: song.id })
            }}
        >
            {isSelected && <Chip size="small" label="Spelas" color="success" icon={<VolumeUp />} sx={{ zIndex: 1000, position: "absolute", top: 4, right: 4 }} />}
            <Box sx={{ display: "flex", flexDirection: "row" }}>
                <CardMedia component="img" sx={{ objectFit: "cover", width: "100px", height: "100px" }} image={song.img ?? logo} alt="Skivomslag" />

                <CardContent sx={{ flex: "1 0 auto" }}>
                    <Box>
                        <Stack>
                            <Text bold displayedLines={2}>
                                {song.title}
                            </Text>
                        </Stack>
                        {hasAdditionalInformation && (
                            <Stack>
                                {song.description && (
                                    <Text size="tiny" displayedLines={2}>
                                        {`${song.description}`}
                                    </Text>
                                )}
                                {song.keytone && !song.artist && (
                                    <TextRow gap={"tiny"}>
                                        <Text bold size={"tiny"}>
                                            Tonart:
                                        </Text>
                                        <Text prettify size={"tiny"}>
                                            {song.keytone}
                                        </Text>
                                    </TextRow>
                                )}
                                {song.artist && (
                                    <Text hideOverflow size={"tiny"}>
                                        {song.artist}
                                    </Text>
                                )}
                                {song.backingTrack && (
                                    <TextRow gap={"tiny"}>
                                        <Text bold size={"tiny"}>
                                            Bakgrundspår:
                                        </Text>
                                        <Text prettify size={"tiny"}>
                                            Ja
                                        </Text>
                                    </TextRow>
                                )}
                            </Stack>
                        )}
                    </Box>
                </CardContent>
            </Box>
        </Card>
    )
}

const DesktopCard = ({ isSelected, song, changeCurrentSong, hasAdditionalInformation }: DesktopCardProps) => {
    const [expanded, setExpanded] = useState(false)

    const handleExpandClick = (hasAdditionalInformation: any) => {
        if (hasAdditionalInformation) {
            setExpanded(!expanded)
        }
        return
    }

    return (
        <Card
            sx={{
                width: "100%",
                background: isSelected ? "white" : "white",
                cursor: "pointer",
                outline: isSelected ? "2px solid lightgreen" : "",
                position: "relative",
                "&:hover": {
                    border: "2px solid lightgrey",
                    "& > .MuiSvgIcon-root": { opacity: 1, bottom: "35%" },
                },
            }}
        >
            {isSelected && <Chip size="small" label="Spelas" color="success" icon={<VolumeUp />} sx={{ zIndex: 1000, position: "absolute", top: 2, right: 4 }} />}

            <CardMedia
                component="img"
                height="150"
                sx={{ objectFit: "cover" }}
                image={song.img ?? logo}
                alt="Skivomslag"
                onClick={() => {
                    changeCurrentSong({ songId: song.id })
                }}
            />

            <CardContent style={{ paddingBottom: 10 }} onClick={() => handleExpandClick(hasAdditionalInformation)} sx={{}}>
                <Box sx={{ display: "flex" }}>
                    <Stack>
                        <Text bold displayedLines={2}>
                            {song.title}
                        </Text>
                    </Stack>
                    {hasAdditionalInformation && (
                        <ExpandMore sx={{ padding: 0 }} expand={expanded} aria-expanded={expanded} aria-label="show more">
                            <ExpandMoreIcon />
                        </ExpandMore>
                    )}
                </Box>
            </CardContent>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent style={{ paddingTop: 0, paddingBottom: 10 }}>
                    <Stack>
                        {song.description && (
                            <Text size="tiny" displayedLines={2}>
                                {`${song.description}`}
                            </Text>
                        )}
                        {song.keytone && !song.artist && (
                            <TextRow gap={"tiny"}>
                                <Text bold size={"tiny"}>
                                    Tonart:
                                </Text>
                                <Text prettify size={"tiny"}>
                                    {song.keytone}
                                </Text>
                            </TextRow>
                        )}
                        {song.artist && (
                            <Text hideOverflow size={"tiny"}>
                                {song.artist}
                            </Text>
                        )}
                        {song.backingTrack && (
                            <TextRow gap={"tiny"}>
                                <Text bold size={"tiny"}>
                                    Bakgrundspår:
                                </Text>
                                <Text prettify size={"tiny"}>
                                    Ja
                                </Text>
                            </TextRow>
                        )}
                    </Stack>
                </CardContent>
            </Collapse>
            {!isSelected && (
                <PlayCircleFilledWhite
                    sx={{
                        fontSize: "3rem",
                        color: (theme) => theme.palette.success.main,
                        zIndex: 1000,
                        position: "absolute",
                        bottom: "40%",
                        right: "12%",
                        transform: "translate(50%, 50%)",
                        opacity: 0,
                        transition: "opacity 0.2s ease-in-out, bottom 0.3s ease-in-out",
                        backgroundColor: "white",
                        borderRadius: "100%",
                    }}
                />
            )}
        </Card>
    )
}

export default SongCard
